<template>
    <v-layout row class="pa-3 mb-2">
        <v-flex xs12 sm6 offset-sm3>
            <v-card flat>
                <v-card-title class="justify-center subheading pa-0"><b>{{ $t("takeoutprinterlist.title")}}</b></v-card-title>
                <v-layout row wrap class="mb-5">
                    <v-flex xs12 v-for="box_id in boxIds" :key="box_id">
                        <v-list subheader>
                            <v-subheader>{{ packages.find((a) => a.box_id === box_id).box_name }}({{ box_id }})</v-subheader>
                            <v-list-tile v-for="pdata in packages.filter((a) => a.box_id === box_id)" :key="pdata.track_no">
                                <v-list-tile-action>
                                    <v-checkbox color="indigo" v-model="pdata.selected"></v-checkbox>
                                </v-list-tile-action>
                                <v-list-tile-content>
                                    <v-list-tile-title>{{ $t("takeoutchange.cell") }} {{ pdata.door_id }} &nbsp;{{ pdata.status }} </v-list-tile-title>
                                    <v-list-tile-sub-title>{{ $t("takeoutprinterlist.track-no") }}: {{ pdata.track_no || "" }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-btn
                            large
                            block
                            class="ma-1 white--text"
                            color="purple darken-2"
                            @click="stickerPrinter"
                            :disabled="printClick()"
                            >{{ $t("takeoutprinterlist.print") }}</v-btn
                        >
                    </v-flex>
                    <v-flex xs12>
                        <v-btn large block class="ma-1 white--text" color="black darken-2" @click="leave">{{ $t("takeoutchange.cancel") }}</v-btn>
                    </v-flex>
                </v-layout>
                <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
            </v-card>
            <v-dialog persistent v-model="emptyDialog" width="250">
                <v-card class="text-xs-center">
                    <v-card-text  class="title">
                        <v-icon left>print</v-icon><b>{{ printMessage }}</b>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn block class="subheading" color="primary" @click="leave()"> {{ $t('takeout.back') }} </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from "axios";
import store, { HostUrl } from "@/store";
import BoxIdWatcherMixin from "@/mixins/BoxIdWatcher";
import ControlResultWatcher from "@/mixins/ControlResultWatcher";
import PutinMixin from "@/mixins/PutinMixin";
import { getAxiosConfig } from "@/utils/AuthService";
import i18n from "@/i18n";
import { publish } from '@/utils/MQTTClient'
import WaitDialogControl from "@/mixins/WaitDialogControl";

export default {
    name: "docsClerkPackageList",
    mixins: [BoxIdWatcherMixin, ControlResultWatcher, PutinMixin, WaitDialogControl],
    data() {
        store.commit('setPageHome', 2)
        return {
            stepNo: 1,
            titles: [i18n.t("menu.buttons.print-list")],
            headers: [
                {
                    sortable: false,
                    width: "10px",
                },
                { text: i18n.t("takeoutchange.box-id"), value: "box_id" },
                { text: i18n.t("takeoutchange.box-name"), value: "box_name" },
                { text: i18n.t("takeoutchange.cell"), value: "door_id" },
                { text: i18n.t("takeoutprinterlist.track-no"), value: "track_no" },
            ],
            emptyDialog: false,
            printOFF: 0,
            serial_no: "",
            printMessage: i18n.t('takeoutprinterlist.printer-login-first'),
            packages: [],
            receiverPhone: ""
        };
    },
    computed: {
        boxIds() {
            if (this.packages.length === 0) {
                return [];
            }
            const map = new Map();
            for (const item of this.packages) {
                if (!map.has(item.box_id)) {
                    map.set(item.box_id, true); // set any value to Map
                }
            }
            console.log(map.keys());
            return Array.from(map.keys());
        }
    },
    methods: {
        printClick (value) {
            if (this.packages.filter((a) => a.selected).length === 0) return true
            else if (value) return value
            else return false
        },
        async getPackageStatus() {
            let url = `/api/clerk/wash_takeout/list`
            if (store.getters.user.id === 0 || url === null || url === undefined) {
                return;
            }
            this.showDialog(i18n.t("takeoutprinterlist.get-list"), i18n.t("takeout.fail-get-list"));
            let self = this;
            await axios
                .get(HostUrl + url, getAxiosConfig())
                .then(function(response) {
                    console.log(response);
                    self.serial_no = response.data.serial_no
                    const packages = response.data.packages;
                    let packageArray = [];
                    for (const track_no in packages) {
                        let { box_id, box_name, door_id, status } = packages[track_no];
                        let yy = status
                        if (yy == '未收衣')
                            status = i18n.t("takeoutprinterlist.wait-to-wash-in-box")
                        else
                            status = i18n.t("takeoutprinterlist.wait-to-wash-out-box")
                        packageArray.push({
                            selected: false,
                            track_no,
                            box_id,
                            box_name,
                            door_id,
                            status,
                        });
                    }
                    self.packages = packageArray;
                    if (self.serial_no == null)
                        self.emptyDialog = true
                    self.closeDialog();
                })
                .catch(function(error) {
                    console.log("error", error);
                    self.closeDialog();
                });
        },
        stickerPrinter() {
            this.printClick(false)
            let self = this;
            let track_nos_list = []
            let track_nos = ''
            const printList = self.packages.filter((a) => a.selected);
            printList.forEach((pdata) => {track_nos_list.push(pdata.track_no), track_nos+= pdata.track_no+', '})
            this.printClick(false)
            this.showDialog(i18n.t('takeoutprinterlist.print')+':['+track_nos+']', i18n.t('takeoutprinterlist.fail-print'))
            setTimeout(() => {
                var topic = `printer/`+self.serial_no+`/notify`
                publish(topic, {
                    track_no_list: track_nos_list
                })
                self.closeDialog()
            }, 5000);
        },
        leave() {
            this.init();
            this.$router.push(`/${i18n.locale}/washhost/menu`);
        },
    },
    activated() {
        this.getPackageStatus();
    },
};
</script>

<style scoped>
.nobull {
    list-style-type: none;
    padding-left: 0px;
}
.bold {
    font-weight: bold;
    font-size: 1.25rem;
}
</style>
